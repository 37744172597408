.company {
  position: relative;
  padding: 85px 0 0;

  @include mq(md) {
    padding: 155px 0 0;
  }

  &__inner {
    padding: 0 25px;

    @include mq(md) {
      @include container();

      padding: 0;
    }
  }

  &__heading {
    text-align: center;
  }

  &__lead {
    margin-top: 17px;
    font-size: 15px;
    line-height: 1.74;
    text-align: center;
    letter-spacing: 0.05em;

    @include mq(md) {
      margin-top: 34px;
      font-size: 14px;
      letter-spacing: 0.13em;
    }

    br {
      @include mq(md) {
        display: none;
      }
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 33px;

    @include mq(md) {
      margin-top: 64px;
    }
  }

  &__nav-item {
    width: calc(50% - 10px);

    @include mq(md) {
      width: 211px;
    }

    &:nth-child(n + 3) {
      margin-top: 37px;

      @include mq(md) {
        margin-top: 0;
      }
    }

    &:last-child {
      width: 100%;

      @include mq(md) {
        width: 211px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #d9d9d9;

    @include mq(md) {
      padding-bottom: 27px;
      transition: border-color 300ms $ease-out-quad;

      &:hover {
        border-color: #04612e;
      }
    }
  }

  &__item-image {
    position: relative;
    height: 90px;

    @at-root .company__item--01 & {
      top: 3px;
      left: 0;

      @include mq(md) {
        top: 4px;
        left: 4px;
      }
    }

    @at-root .company__item--02 & {
      top: 0;
      left: 0;

      @include mq(md) {
        top: 0;
        left: 2px;
      }
    }

    @at-root .company__item--03 & {
      top: 4px;
      left: 2px;

      @include mq(md) {
        top: -2px;
        left: 1px;
      }
    }

    @at-root .company__item--04 & {
      top: 9px;
      left: 1px;

      @include mq(md) {
        top: 4px;
        left: -1px;
      }
    }

    @at-root .company__item--05 & {
      top: 7px;
      left: -1px;

      @include mq(md) {
        top: 10px;
        left: 1px;
      }
    }

    @include mq(md) {
      height: 107px;
    }

    img {
      @include mq(md) {
        width: auto;
        height: auto;
      }
    }
  }

  &__item-heading {
    margin-top: 9px;
    font-size: 15px;
    font-weight: 500;
    color: #196537;
    letter-spacing: 0.1em;

    @include mq(md) {
      margin-top: 32px;
      font-size: 14px;
    }
  }
}
