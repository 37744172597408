.mv {
  position: relative;
  z-index: 1;
  height: 502.5px;
  overflow: hidden;

  @include mq(md) {
    height: 100vh;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;

    @include mq(md) {
      @include container();
    }
  }

  &__catch {
    color: #fff;
    text-align: center;
  }

  &__main {
    font-family: 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', serif;
    font-size: 45px;

    @include mq(md) {
      font-size: 60px;
    }
  }

  &__text {
    display: inline-block;
    width: 140px;
    opacity: 0;

    @at-root .mv.is-active & {
      animation: fadeIn 1.5s 0.5s linear forwards;
    }

    @include mq(md) {
      width: 185px;
    }

    + .mv__text {
      margin-left: 15px;

      @at-root .mv.is-active & {
        animation: fadeIn 1.5s 1.5s linear forwards;
      }
    }

    img {
      width: 100%;
    }
  }

  &__sub {
    margin: 28px 0 0;
    opacity: 0;

    @at-root .mv.is-active & {
      animation: fadeIn 3s 2.5s linear forwards;
    }

    img {
      width: 203px;

      @include mq(md) {
        width: 460px;
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url('/assets/images/pages/index/mv_image01_sm.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @include mq(md) {
      background-image: url('/assets/images/pages/index/mv_image01_md.jpg');
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
