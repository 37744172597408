.recruit {
  margin-top: 40px;

  @include mq(md) {
    margin-top: 177px;
    margin-bottom: 181px;
  }

  &__inner {
    padding: 0 36px;

    @include mq(md) {
      @include container();

      position: relative;
      padding: 0;
    }
  }

  &__heading {
    @include mq(md) {
      margin-left: 1px;
    }
  }

  &__lead {
    margin: 13px 0 0;
    font-size: 31.5px;
    font-weight: 700;
    line-height: 1.176;
    letter-spacing: 0.05em;

    @include mq(md) {
      margin: 22px 0 0 -4px;
      font-size: 43px;
      line-height: 1.33;
    }
  }

  &__text {
    margin-top: 17px;
    font-size: 15px;
    line-height: 1.74;
    letter-spacing: 0.14em;

    @include mq(md) {
      width: 550px;
      margin-top: 24px;
      font-size: 14px;
      line-height: 2.43;
    }
  }

  &__link {
    margin-top: 25px;

    @include mq(md) {
      margin-top: 32px;
    }
  }

  &__button {
    @include button-sm($color: #196537, $width: 189px);

    @include mq(md) {
      @include button-md($width: 218px);
    }
  }

  &__image {
    margin: 40px -36px 0;

    @include mq(md) {
      position: absolute;
      top: -19px;
      left: calc(50% + 39px);
      margin: 0;
    }

    img {
      width: 100%;
      height: auto;

      @include mq(md) {
        width: auto;
        height: auto;
      }
    }
  }
}
