.sec-movie {
  padding: 70px 0 40px;
  background: #242926;

  .title {
    padding: 0 0 20px;
    font-size: 31.5px;
    font-weight: 700;
    color: #fff;
    text-align: center;

    @include mq(md) {
      font-size: 43px;
    }

    span {
      display: block;
      padding: 0 0 10px;
      font-size: 20px;
      color: #04612e;

      @include mq(md) {
        font-size: 30px;
      }
    }
  }

  .inner {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include mq(md) {
      width: 1000px;
      padding: 0;
      margin: 0 auto;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      border: none;

      @media screen and (max-width: 768px) {
        height: 100%;
      }

      @include mq(md) {
        position: static;
      }
    }

    p {
      @include mq(md) {
        width: 1000px;
        padding: 0 0 50px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
