.other {
  padding: 38px 25px 55px;
  background-color: #f9f9f9;

  @include mq(md) {
    padding: 69px 0 90px;
  }

  &__inner {
    @include mq(md) {
      @include container();

      display: flex;
    }
  }

  &__block--news {
    @include mq(md) {
      width: 690px;
    }
  }

  &__block--salon {
    @include mq(md) {
      width: 441px;
      padding-top: 33px;
      margin-left: auto;
    }
  }
}
