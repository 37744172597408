.service {
  position: relative;
  padding-bottom: 50px;
  margin-top: 50px;

  @include mq(md) {
    padding-bottom: 0;
    margin-top: 131px;
  }

  &_wrap {
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    bottom: -13.3%;
    display: block;
    width: 100%;
    padding-top: 191.33333333%;
    background-image: url('/assets/images/pages/index/service_bg_sm.jpg');
    background-size: cover;

    @include mq(md) {
      right: 0;
      bottom: -503px;
      width: 772px;
      height: 1465px;
      padding-top: 0;
      background-image: url('/assets/images/pages/index/service_bg_md.jpg');
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding: 62px 25px 0;

    @include mq(md) {
      display: flex;
      padding: 88px 0 91px;
    }
  }

  &__contents {
    position: relative;
    padding-left: 9px;

    @include mq(md) {
      @include container();

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 0;
    }
  }

  &__contents-inner {
    @include mq(md) {
      position: relative;
      top: 0.7%;
    }
  }

  &__text {
    margin: 29px 0 0 2px;
    font-size: 15px;
    line-height: 1.74;
    color: #fff;
    letter-spacing: 0.05em;

    @include mq(md) {
      margin-top: 49px;
      font-size: 14px;
      line-height: 2.43;
      letter-spacing: 0.1em;
    }
  }

  &__link {
    margin: 30px 0 0 3px;

    @include mq(md) {
      margin: 51px 0 0 1px;
    }
  }

  &__button {
    @include button-sm($color: #fff, $width: 164px);

    @include mq(md) {
      @include button-md($width: 218px);
    }
  }

  &__list {
    margin-top: 40px;
    margin-right: -25px;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      width: 62.5%;
      margin: 0 0 0 auto;
    }
  }

  &__list-item {
    @include mq(md) {
      width: 33.33333333%;
    }
  }

  &__item {
    position: relative;
    display: block;
  }

  &__item-number {
    position: absolute;
    top: 7px;
    left: 16px;
    z-index: 1;
    padding: 0 0 4px;
    border-bottom: 1px solid #196537;

    @include mq(md) {
      top: 12px;
      left: 21px;
      padding-bottom: 6px;
    }
  }

  &__item-image {
    position: relative;
    padding-top: 66.19115549%;
    overflow: hidden;
    background-color: #afafaf;

    @include mq(md) {
      padding-top: 83.83233532%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;

      @include mq(md) {
        transition: transform 400ms $ease-out-quart;

        @at-root .service__item:hover & {
          transform: scale(1.06);
        }
      }
    }
  }

  &__item-heading {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 9px 0 9px 16px;
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.1em;
    background-color: rgba(0, 0, 0, 0.6);

    @include mq(md) {
      padding: 10px 0 12px 23px;
      font-size: 16px;
    }
  }

  &::before {
    position: absolute;
    z-index: 1;
    display: block;
    width: 90.8%;
    height: 100%;
    content: '';
    background-color: #196537;

    @include mq(md) {
      width: 64.6875%;
    }
  }
}
