.news {
  &__list {
    margin-top: 13px;
    border-top: 1px solid #d9d9d9;

    @include mq(md) {
      margin-top: 32px;
    }
  }

  &__list-item {
    border-bottom: 1px solid #d9d9d9;
  }

  &__item {
    display: block;
    padding: 15px 0 17px;

    @include mq(md) {
      display: flex;
      align-items: center;
      padding: 21px 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__item-date {
    display: block;
    font-size: 11px;

    @include mq(md) {
      width: 88px;
      font-size: 12px;
    }
  }

  &__item-text {
    display: block;
    margin-top: 3px;
    font-size: 14px;
    line-height: 1.43;

    @include mq(md) {
      margin-top: 0;
      letter-spacing: 0.1em;
    }
  }
}
