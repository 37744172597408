@charset "UTF-8";
.company {
  position: relative;
  padding: 85px 0 0;
}
@media (min-width: 48em) {
  .company {
    padding: 155px 0 0;
  }
}
.company__inner {
  padding: 0 25px;
}
@media (min-width: 48em) {
  .company__inner {
    width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
}
.company__heading {
  text-align: center;
}
.company__lead {
  margin-top: 17px;
  font-size: 15px;
  line-height: 1.74;
  text-align: center;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .company__lead {
    margin-top: 34px;
    font-size: 14px;
    letter-spacing: 0.13em;
  }
}
@media (min-width: 48em) {
  .company__lead br {
    display: none;
  }
}
.company__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 33px;
}
@media (min-width: 48em) {
  .company__nav {
    margin-top: 64px;
  }
}
.company__nav-item {
  width: calc(50% - 10px);
}
@media (min-width: 48em) {
  .company__nav-item {
    width: 211px;
  }
}
.company__nav-item:nth-child(n+3) {
  margin-top: 37px;
}
@media (min-width: 48em) {
  .company__nav-item:nth-child(n+3) {
    margin-top: 0;
  }
}
.company__nav-item:last-child {
  width: 100%;
}
@media (min-width: 48em) {
  .company__nav-item:last-child {
    width: 211px;
  }
}
.company__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 18px;
  border-bottom: 1px solid #d9d9d9;
}
@media (min-width: 48em) {
  .company__item {
    padding-bottom: 27px;
    transition: border-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .company__item:hover {
    border-color: #04612e;
  }
}
.company__item-image {
  position: relative;
  height: 90px;
}
.company__item--01 .company__item-image {
  top: 3px;
  left: 0;
}
@media (min-width: 48em) {
  .company__item--01 .company__item-image {
    top: 4px;
    left: 4px;
  }
}

.company__item--02 .company__item-image {
  top: 0;
  left: 0;
}
@media (min-width: 48em) {
  .company__item--02 .company__item-image {
    top: 0;
    left: 2px;
  }
}

.company__item--03 .company__item-image {
  top: 4px;
  left: 2px;
}
@media (min-width: 48em) {
  .company__item--03 .company__item-image {
    top: -2px;
    left: 1px;
  }
}

.company__item--04 .company__item-image {
  top: 9px;
  left: 1px;
}
@media (min-width: 48em) {
  .company__item--04 .company__item-image {
    top: 4px;
    left: -1px;
  }
}

.company__item--05 .company__item-image {
  top: 7px;
  left: -1px;
}
@media (min-width: 48em) {
  .company__item--05 .company__item-image {
    top: 10px;
    left: 1px;
  }
}

@media (min-width: 48em) {
  .company__item-image {
    height: 107px;
  }
}
@media (min-width: 48em) {
  .company__item-image img {
    width: auto;
    height: auto;
  }
}
.company__item-heading {
  margin-top: 9px;
  font-size: 15px;
  font-weight: 500;
  color: #196537;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .company__item-heading {
    margin-top: 32px;
    font-size: 14px;
  }
}

.mv {
  position: relative;
  z-index: 1;
  height: 502.5px;
  overflow: hidden;
}
@media (min-width: 48em) {
  .mv {
    height: 100vh;
  }
}
.mv__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
@media (min-width: 48em) {
  .mv__inner {
    width: 1200px;
    margin: 0 auto;
  }
}
.mv__catch {
  color: #fff;
  text-align: center;
}
.mv__main {
  font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", serif;
  font-size: 45px;
}
@media (min-width: 48em) {
  .mv__main {
    font-size: 60px;
  }
}
.mv__text {
  display: inline-block;
  width: 140px;
  opacity: 0;
}
.mv.is-active .mv__text {
  animation: fadeIn 1.5s 0.5s linear forwards;
}

@media (min-width: 48em) {
  .mv__text {
    width: 185px;
  }
}
.mv__text + .mv__text {
  margin-left: 15px;
}
.mv.is-active .mv__text + .mv__text {
  animation: fadeIn 1.5s 1.5s linear forwards;
}

.mv__text img {
  width: 100%;
}
.mv__sub {
  margin: 28px 0 0;
  opacity: 0;
}
.mv.is-active .mv__sub {
  animation: fadeIn 3s 2.5s linear forwards;
}

.mv__sub img {
  width: 203px;
}
@media (min-width: 48em) {
  .mv__sub img {
    width: 460px;
  }
}
.mv::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("/assets/images/pages/index/mv_image01_sm.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
@media (min-width: 48em) {
  .mv::before {
    background-image: url("/assets/images/pages/index/mv_image01_md.jpg");
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.news__list {
  margin-top: 13px;
  border-top: 1px solid #d9d9d9;
}
@media (min-width: 48em) {
  .news__list {
    margin-top: 32px;
  }
}
.news__list-item {
  border-bottom: 1px solid #d9d9d9;
}
.news__item {
  display: block;
  padding: 15px 0 17px;
}
@media (min-width: 48em) {
  .news__item {
    display: flex;
    align-items: center;
    padding: 21px 0;
  }
  .news__item:hover {
    text-decoration: underline;
  }
}
.news__item-date {
  display: block;
  font-size: 11px;
}
@media (min-width: 48em) {
  .news__item-date {
    width: 88px;
    font-size: 12px;
  }
}
.news__item-text {
  display: block;
  margin-top: 3px;
  font-size: 14px;
  line-height: 1.43;
}
@media (min-width: 48em) {
  .news__item-text {
    margin-top: 0;
    letter-spacing: 0.1em;
  }
}

.other {
  padding: 38px 25px 55px;
  background-color: #f9f9f9;
}
@media (min-width: 48em) {
  .other {
    padding: 69px 0 90px;
  }
}
@media (min-width: 48em) {
  .other__inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
}
@media (min-width: 48em) {
  .other__block--news {
    width: 690px;
  }
}
@media (min-width: 48em) {
  .other__block--salon {
    width: 441px;
    padding-top: 33px;
    margin-left: auto;
  }
}

.recruit {
  margin-top: 40px;
}
@media (min-width: 48em) {
  .recruit {
    margin-top: 177px;
    margin-bottom: 181px;
  }
}
.recruit__inner {
  padding: 0 36px;
}
@media (min-width: 48em) {
  .recruit__inner {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 0;
  }
}
@media (min-width: 48em) {
  .recruit__heading {
    margin-left: 1px;
  }
}
.recruit__lead {
  margin: 13px 0 0;
  font-size: 31.5px;
  font-weight: 700;
  line-height: 1.176;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .recruit__lead {
    margin: 22px 0 0 -4px;
    font-size: 43px;
    line-height: 1.33;
  }
}
.recruit__text {
  margin-top: 17px;
  font-size: 15px;
  line-height: 1.74;
  letter-spacing: 0.14em;
}
@media (min-width: 48em) {
  .recruit__text {
    width: 550px;
    margin-top: 24px;
    font-size: 14px;
    line-height: 2.43;
  }
}
.recruit__link {
  margin-top: 25px;
}
@media (min-width: 48em) {
  .recruit__link {
    margin-top: 32px;
  }
}
.recruit__button {
  box-sizing: border-box;
  display: inline-block;
  width: 189px;
  height: 50px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  color: #196537;
  letter-spacing: 0.08em;
  vertical-align: top;
  border: 1px solid #196537;
  border-radius: 25px;
}
@media (min-width: 48em) {
  .recruit__button {
    transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .recruit__button:hover {
    background-color: #196537;
    color: #fff;
  }
}
.recruit__button-inner, .recruit__button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.recruit__button-label, .recruit__button__label {
  position: relative;
  top: -0.1em;
}
@media (min-width: 48em) {
  .recruit__button {
    width: 218px;
    height: 53px;
    font-size: 12px;
    letter-spacing: 0.08em;
    border-radius: 26.5px;
  }
}
.recruit__image {
  margin: 40px -36px 0;
}
@media (min-width: 48em) {
  .recruit__image {
    position: absolute;
    top: -19px;
    left: calc(50% + 39px);
    margin: 0;
  }
}
.recruit__image img {
  width: 100%;
  height: auto;
}
@media (min-width: 48em) {
  .recruit__image img {
    width: auto;
    height: auto;
  }
}

.salon {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 35px;
  background-color: #fff;
}
@media (min-width: 48em) {
  .salon {
    transition: box-shadow 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .salon:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
}
.salon__contents {
  width: 61.38461539%;
  padding: 0 25px 0 34px;
}
@media (min-width: 48em) {
  .salon__contents {
    position: relative;
    top: -2px;
    padding: 0 45px;
  }
}
.salon__heading {
  font-size: 17px;
  font-weight: 700;
  color: #196537;
}
@media (min-width: 48em) {
  .salon__heading {
    font-size: 23px;
  }
}
.salon__text {
  margin-top: 10px;
  font-size: 9px;
  line-height: 2;
}
@media (min-width: 48em) {
  .salon__text {
    margin-top: 14px;
    font-size: 12px;
  }
}
.salon__image {
  width: 38.61538461%;
}
.salon__image img {
  width: 100%;
  height: auto;
}
.salon::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  content: "";
  border-top: 8px solid #196537;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #196537;
}
@media (min-width: 48em) {
  .salon::before {
    border-width: 11px;
    transition: border-width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .salon:hover::before {
    border-width: 16px;
  }
}

.sec-movie {
  padding: 70px 0 40px;
  background: #242926;
}
.sec-movie .title {
  padding: 0 0 20px;
  font-size: 31.5px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
@media (min-width: 48em) {
  .sec-movie .title {
    font-size: 43px;
  }
}
.sec-movie .title span {
  display: block;
  padding: 0 0 10px;
  font-size: 20px;
  color: #04612e;
}
@media (min-width: 48em) {
  .sec-movie .title span {
    font-size: 30px;
  }
}
.sec-movie .inner {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 48em) {
  .sec-movie .inner {
    width: 1000px;
    padding: 0;
    margin: 0 auto;
  }
}
.sec-movie .inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  border: none;
}
@media screen and (max-width: 768px) {
  .sec-movie .inner iframe {
    height: 100%;
  }
}
@media (min-width: 48em) {
  .sec-movie .inner iframe {
    position: static;
  }
}
@media (min-width: 48em) {
  .sec-movie .inner p {
    width: 1000px;
    padding: 0 0 50px;
    margin: 0 auto;
    text-align: center;
  }
}

.service {
  position: relative;
  padding-bottom: 50px;
  margin-top: 50px;
}
@media (min-width: 48em) {
  .service {
    padding-bottom: 0;
    margin-top: 131px;
  }
}
.service_wrap {
  overflow: hidden;
}
.service__bg {
  position: absolute;
  bottom: -13.3%;
  display: block;
  width: 100%;
  padding-top: 191.33333333%;
  background-image: url("/assets/images/pages/index/service_bg_sm.jpg");
  background-size: cover;
}
@media (min-width: 48em) {
  .service__bg {
    right: 0;
    bottom: -503px;
    width: 772px;
    height: 1465px;
    padding-top: 0;
    background-image: url("/assets/images/pages/index/service_bg_md.jpg");
  }
}
.service__inner {
  position: relative;
  z-index: 2;
  padding: 62px 25px 0;
}
@media (min-width: 48em) {
  .service__inner {
    display: flex;
    padding: 88px 0 91px;
  }
}
.service__contents {
  position: relative;
  padding-left: 9px;
}
@media (min-width: 48em) {
  .service__contents {
    width: 1200px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0;
  }
}
@media (min-width: 48em) {
  .service__contents-inner {
    position: relative;
    top: 0.7%;
  }
}
.service__text {
  margin: 29px 0 0 2px;
  font-size: 15px;
  line-height: 1.74;
  color: #fff;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .service__text {
    margin-top: 49px;
    font-size: 14px;
    line-height: 2.43;
    letter-spacing: 0.1em;
  }
}
.service__link {
  margin: 30px 0 0 3px;
}
@media (min-width: 48em) {
  .service__link {
    margin: 51px 0 0 1px;
  }
}
.service__button {
  box-sizing: border-box;
  display: inline-block;
  width: 164px;
  height: 50px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.08em;
  vertical-align: top;
  border: 1px solid #fff;
  border-radius: 25px;
}
@media (min-width: 48em) {
  .service__button {
    transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .service__button:hover {
    background-color: #fff;
    color: #04612e;
  }
}
.service__button-inner, .service__button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.service__button-label, .service__button__label {
  position: relative;
  top: -0.1em;
}
@media (min-width: 48em) {
  .service__button {
    width: 218px;
    height: 53px;
    font-size: 12px;
    letter-spacing: 0.08em;
    border-radius: 26.5px;
  }
}
.service__list {
  margin-top: 40px;
  margin-right: -25px;
}
@media (min-width: 48em) {
  .service__list {
    display: flex;
    flex-wrap: wrap;
    width: 62.5%;
    margin: 0 0 0 auto;
  }
}
@media (min-width: 48em) {
  .service__list-item {
    width: 33.33333333%;
  }
}
.service__item {
  position: relative;
  display: block;
}
.service__item-number {
  position: absolute;
  top: 7px;
  left: 16px;
  z-index: 1;
  padding: 0 0 4px;
  border-bottom: 1px solid #196537;
}
@media (min-width: 48em) {
  .service__item-number {
    top: 12px;
    left: 21px;
    padding-bottom: 6px;
  }
}
.service__item-image {
  position: relative;
  padding-top: 66.19115549%;
  overflow: hidden;
  background-color: #afafaf;
}
@media (min-width: 48em) {
  .service__item-image {
    padding-top: 83.83233532%;
  }
}
.service__item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
@media (min-width: 48em) {
  .service__item-image img {
    transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .service__item:hover .service__item-image img {
    transform: scale(1.06);
  }
}
.service__item-heading {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 9px 0 9px 16px;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.1em;
  background-color: rgba(0, 0, 0, 0.6);
}
@media (min-width: 48em) {
  .service__item-heading {
    padding: 10px 0 12px 23px;
    font-size: 16px;
  }
}
.service::before {
  position: absolute;
  z-index: 1;
  display: block;
  width: 90.8%;
  height: 100%;
  content: "";
  background-color: #196537;
}
@media (min-width: 48em) {
  .service::before {
    width: 64.6875%;
  }
}