.salon {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 35px;
  background-color: #fff;

  @include mq(md) {
    transition: box-shadow 300ms $ease-out-quad;

    &:hover {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    }
  }

  &__contents {
    width: 61.38461539%;
    padding: 0 25px 0 34px;

    @include mq(md) {
      position: relative;
      top: -2px;
      padding: 0 45px;
    }
  }

  &__heading {
    font-size: 17px;
    font-weight: 700;
    color: #196537;

    @include mq(md) {
      font-size: 23px;
    }
  }

  &__text {
    margin-top: 10px;
    font-size: 9px;
    line-height: 2;

    @include mq(md) {
      margin-top: 14px;
      font-size: 12px;
    }
  }

  &__image {
    width: 38.61538461%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    content: '';
    border-top: 8px solid #196537;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #196537;

    @include mq(md) {
      border-width: 11px;
      transition: border-width 300ms $ease-out-quad;

      @at-root .salon:hover::before {
        border-width: 16px;
      }
    }
  }
}
